.all-reports-data-table th {
  font-size: 26px;
  background-color: #4682b4 !important;
  color: #fff !important;
}

.all-reports-data-table tr td {
  font-size: 24px;
  padding: 20px !important;
  text-transform: capitalize;
}
.job-card-dashboard-all-reports {
  margin-bottom: 200px;
}
.job-card-dashboard-all-reports .ant-table-measure-row {
  /* display: none; */
}
.section-container-header-new {
  text-transform: capitalize;
}
.line-dash-board-secion {
  margin-top: 50px;
}
.line-dash-board-secion-table .ant-table-measure-row {
  /* display: none; */
}
.main-all-sec-report {
  display: flex;
}
.main-all-sec-report > div {
  width: 50%;
}
