.footer-wrapper {
  background-color: #333333;
  padding: 50px 10%;
  display: inline-block;
  width: 100%;
}
.footer-top {
  color: #ffffff;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
  padding-bottom: 20px;
}
.whatapp-icon-png-sec {
  position: fixed;
  bottom: 10px;
  right: 10px !important;
  /* z-index: 5 !important; */
  border: none;
  height: 70px;
  width: 70px;
  background: none;
}
.whatapp-icon-png-sec img {
  width: 100%;
}

.foo-top-left {
  width: 80%;
  float: left;
  font-weight: 300;
}
.foo-top-left h2,
.foo-bottom-left p {
  font-weight: 300;
  color: #fff;
}
.foo-top-right {
  width: 20%;
  float: left;
  text-align: right;
}
.footer-bottom {
  display: inline-block;
  margin-top: 20px;
}
.footer-bottom {
  width: 100%;
}
.foo-bottom-left {
  width: 30%;
  float: left;
}
.foo-bottom-right {
  width: 70%;
  float: left;
  text-align: right;
}
.foo-bottom-left {
  color: #f7fffd;
}
.elementor-inline-items li {
  display: inline;
  margin-left: 50px;
}
.elementor-inline-items li a {
  color: #4682b4;
  text-decoration: auto;
}
.elementor-inline-items li a:hover {
  color: #fff;
}
.all-rights-reser {
  padding: 15px 10%;
  font-size: 12px;
  background-color: #333333;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.19);
}
.foo-top-right button {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #1db954;
  padding: 16px 40px 16px 40px;
  border: none;
  border-radius: 5px;
  color: #fff;
}
.Privacy-Policy-sec {
  display: flex;
  gap: 50px;
  justify-content: end;
  margin-top: -50px;
}
.Privacy-Policy-sec p {
  color: #fff;
  color: #4682b4;
  cursor: pointer;
  font-size: 14px;
}
@media (max-width: 700px) {
  .footer-wrapper {
    padding: 20px;
  }
  .footer-bottom {
    display: block;
    margin-top: 0px;
  }
  .foo-bottom-left,
  .foo-bottom-right {
    width: 100%;
    text-align: left;
  }
  .foo-bottom-left p {
    margin-bottom: 5px;
    font-size: 20px;
  }
  .Privacy-Policy-sec {
    display: block;
    margin-top: 0px;
  }
  .elementor-icon-list-items {
    padding-left: 0px;
    margin-top: 20px;
  }
  .elementor-icon-list-items li {
    /* margin-left: 20px; */
    margin-left: 0px;
    width: 100%;
    display: block;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .all-rights-reser {
    padding-left: 20px;
  }
}
